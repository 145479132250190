import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import Expression from "../../components/svg/Expression";
import CarsayoCharacterPopup from "../../components/CarsayoCharacterPopup";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character04 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="character__section04">
        <div>
          <div
            className="company__section__title character"
            style={{
              backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
            }}
          >
            <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
              CHARACTER CONCEPT
              <span>캐릭터 컨셉</span>
            </VerticalMoveAnimation>
          </div>
          <div className="character__contents">
            <div className="left">
              <VerticalMoveAnimation
                element="div"
                className="left__title"
                duration={0.5}
                direction="top"
              >
                <p>#3 HEROES 2025</p>
                <h3>
                  <span>카공이 </span>
                </h3>
              </VerticalMoveAnimation>
              <div className="left__contents">
                <HorizontalMoveAnimation
                  element="div"
                  delay={0.2}
                  duration={0.5}
                  direction="left"
                >
                  <span>
                    차와 대화가 가능해 문제가 무엇인지 금방 파악할 수 있다고
                    한다.
                  </span>
                </HorizontalMoveAnimation>
                <HorizontalMoveAnimation
                  element="div"
                  delay={0.3}
                  duration={0.5}
                  direction="left"
                >
                  <span>
                    차량 관리에 부담을 느끼는 고객들의 부담감을 덜어주는 듯
                    듬직하고
                  </span>
                  <span>
                    큰 덩치를 가지고 있으며 시원하고 털털한 성격으로 수다 떠는
                    것을 좋아하는데,
                  </span>
                </HorizontalMoveAnimation>
                <HorizontalMoveAnimation
                  element="div"
                  delay={0.3}
                  duration={0.5}
                  direction="left"
                >
                  <span>
                    카공이 앞에서 섣불리 엔진 등에 대한 이야기를 꺼냈다가는
                  </span>
                  <span>날밤을 샐 수도 있다고 하니 조심!</span>
                </HorizontalMoveAnimation>
              </div>
              <Expression
                textColor="#303056"
                fill="#A7DE93"
                clickEvent={setPopupOpen}
              />
              <div className="character-clip-box">
                <img
                  src="../imgs/png/character-clip04.png"
                  alt="캐릭터 클립 이미지4"
                />
                <span className="clip-bg">
                  <img src="../imgs/png/clip.png" alt="클립이미지" />
                </span>
              </div>
            </div>
            <div className="right">
              <img
                src="../imgs/svg/character-section04-img.svg"
                alt="카공이  이미지"
              />
              <div className="character-nameTag">
                <div>
                  <p>차량의 모든 부분을 세밀하게 알고있는</p>
                  <h5>카공이</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarsayoCharacterPopup
        isShown={keyId === "section04" ? isPopupOpen : false}
        onClose={setPopupOpen}
        title={"카공이"}
        bgColor={
          "linear-gradient(180deg,rgba(90.84, 134.94, 75.34, 0.97) 0%,rgba(11.74, 55.25, 31.75, 0.87) 100%) #ffffff"
        }
      >
        <div
          className="company__section__title character"
          style={{
            scale: "90%",
            margin: "0 auto",
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <h3>
            CHARACTER OVERVIEW
            <span>캐릭터 미리보기</span>
          </h3>
        </div>
        <div className="character__popup__contents">
          <ul>
            <li>
              <img src="../imgs/png/character03_01.png" alt="카공이이미지1" />
            </li>
            <li>
              <img src="../imgs/png/character03_02.png" alt="카공이이미지2" />
            </li>
            <li>
              <img src="../imgs/png/character03_03.png" alt="카공이이미지3" />
            </li>
            <li>
              <img src="../imgs/png/character03_04.png" alt="카공이이미지4" />
            </li>
            <li>
              <img src="../imgs/png/character03_05.png" alt="카공이이미지5" />
            </li>
            <li>
              <img src="../imgs/png/character03_06.png" alt="카공이이미지6" />
            </li>
            <li>
              <img src="../imgs/png/character03_07.png" alt="카공이이미지7" />
            </li>
            <li>
              <img src="../imgs/png/character03_08.png" alt="카공이이미지8" />
            </li>
          </ul>
        </div>
        <div
          style={{
            padding: "3.375rem 21.625rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={setPopupOpen}
            style={{
              fontSize: ".8125rem",
              lineHeight: "1.125rem",
              border: "1px solid #E5E5EC",
              padding: ".75rem",
              width: "6.25rem",
              minWidth: "100px",
              color: "#E5E5EC",
            }}
          >
            닫기
          </button>
        </div>
      </CarsayoCharacterPopup>
    </section>
  );
};
export default Character04;
