import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Overview04 = () => {
  return (
    <section>
      <div className="overview__section04">
        <div className="company__section__title">
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            HISTORY
            <span>기업 연혁</span>
          </VerticalMoveAnimation>
        </div>
        <div className="overview__section04__content">
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            className="title"
          >
            2024
          </VerticalMoveAnimation>
          <div className="box">
            <ul className="history">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <ul className="history__detail">
                  {/* <li className="content">
                    <span>2024.07</span>
                    <p style={{ display: "none" }}>
                      <span>자동차 플랫폼 최초</span>
                      <span>RE100 가입 기업</span>
                    </p>
                    <img
                      style={{
                        maxWidth: "auto",
                        width: "auto",
                        height: "calc(100% - 4vh)",
                        objectFit: "contain",
                      }}
                      src="../imgs/svg/history-start-img.svg"
                      alt="RE100가입기업인증서"
                    />
                  </li> */}
                  <li
                    className="content rotate"
                    style={{ borderRadius: "2.5rem" }}
                  >
                    <span>2024.06</span>
                    <p>공식 카사요 앱 출시</p>
                    <img src="../imgs/png/mockup.png" alt="앱 목업 이미지" />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.4}
                direction="left"
                duration={0.5}
              >
                <ul className="history__detail">
                  <li className="content">
                    <span>2024.05</span>
                    <p>
                      <span>벤처 기업 인증(혁신 성장형)</span>
                      <span className="small">
                        중소벤처기업부 벤처인증 획득
                      </span>
                    </p>
                    <img
                      src="../imgs/png/ventureImg.png"
                      alt="벤처기업 인증 이미지"
                    />
                  </li>
                  <li className="content">
                    <span>2024.05</span>
                    <p>
                      <span>기업 부설 연구소 설립</span>
                      <span className="small">
                        과학기술정보통신부 인정서 획득
                      </span>
                    </p>
                    <img
                      src="../imgs/png/laboratoryImg.png"
                      alt="기업 부설 연구소 설립 이미지"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </ul>
          </div>
        </div>
        {/* <div className="overview__section04__content">
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            className="title"
          >
            2024
          </VerticalMoveAnimation>
          <div className="history-start">
            <div className="left">
              <HorizontalMoveAnimation
                element="span"
                delay={0.2}
                direction="left"
                duration={0.5}
              >
                2024.07
              </HorizontalMoveAnimation>
              <h4>
                <HorizontalMoveAnimation
                  element="span"
                  delay={0.3}
                  direction="left"
                  duration={0.5}
                >
                  자동차 플랫폼 최초
                </HorizontalMoveAnimation>
                <HorizontalMoveAnimation
                  element="strong"
                  delay={0.4}
                  direction="left"
                  duration={0.5}
                >
                  RE100 가입 기업
                </HorizontalMoveAnimation>
              </h4>
              <HorizontalMoveAnimation
                element="div"
                delay={0.5}
                direction="left"
                duration={0.5}
              >
                <ul>
                  <li>
                    <div>
                      <img src="../imgs/svg/data.svg" alt="데이터아이콘" />
                    </div>
                    <p>자가발전, 전력구매계약, 재생 에너지 공급인증서</p>
                  </li>
                  <li>
                    <div>
                      <img src="../imgs/svg/global.svg" alt="글로벌아이콘" />
                    </div>
                    <p>RE100-2024-07-02-01</p>
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </div>
            <OpacityAnimation element="div" delay={0.2} duration={1}>
              <img
                src="../imgs/svg/history-start-img.svg"
                alt="RE100가입기업인증서"
              />
            </OpacityAnimation>
          </div>
        </div> */}
      </div>
    </section>
  );
};
export default Overview04;
