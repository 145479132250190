import { useEffect, useRef, useState } from "react";
import CarsayoFullPopup from "../../components/CarsaypPopup";
import CarsayoCI from "../../components/svg/CarsayoCI";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const CarsayoMobileOverView = () => {
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const [biggerScreen, setBiggerScreen] = useState<string>(
    window.innerWidth > window.innerHeight ? "width" : "height"
  );
  const [imgPopup, setImgPopup] = useState({
    title: "",
    url: "",
    content: "",
  });

  const handleClickEvents = {
    openImgPopup: (title: string, url: string, content?: string) => () => {
      setImgPopup({
        title: title,
        url: url,
        content: content || "",
      });
      setPopupOpen(!isPopupOpen);
    },
    moveHash: (id: string) => () => {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 56,
          behavior: "smooth",
        });
      }
    },
  };

  // const scrollToHash = () => {
  //   if (window.location.hash) {
  //     const element = document.getElementById(
  //       window.location.hash.substring(1)
  //     );
  //     if (element) {
  //       window.scrollTo({ top: element.scrollTop, behavior: "smooth" });
  //     }
  //   }
  // };
  // sectioin01 - 기업개요
  const Section01 = () => {
    return (
      <section className="m-overview01">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title"
        >
          기업개요
        </VerticalMoveAnimation>
        <ul>
          <OpacityAnimation element="li" duration={1} delay={0.2}>
            <button onClick={handleClickEvents.moveHash("overview01")}>
              <h3>기업 정보</h3>
              <p>자동차 쇼핑 통합 플랫폼</p>
            </button>
          </OpacityAnimation>
          <OpacityAnimation element="li" duration={1.5} delay={0.25}>
            <button onClick={handleClickEvents.moveHash("overview02")}>
              <h3>사업 영역</h3>
              <p>자동차 시장을 선도할 하이퍼 스케일</p>
            </button>
          </OpacityAnimation>
          <OpacityAnimation element="li" duration={1} delay={0.3}>
            <button onClick={handleClickEvents.moveHash("overview03")}>
              <h3>기업 연혁</h3>
              <p>지금까지 달려온, 앞으로 달려갈 길</p>
            </button>
          </OpacityAnimation>
        </ul>
      </section>
    );
  };
  // section02 - 기업정보
  const Section02 = () => {
    return (
      <section className="m-overview02" id="overview01">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          기업정보
        </VerticalMoveAnimation>
        <div>
          <HorizontalMoveAnimation
            element="p"
            delay={0.2}
            duration={0.5}
            direction="left"
          >
            사업분야
          </HorizontalMoveAnimation>
          <OpacityAnimation element="div" delay={0.2} duration={1.5}>
            <h3>
              <span>자동차 판매 및 구매</span>
              <span>자동차 금융 및 보험, 앱 서비스 개발 외 다수</span>
            </h3>
          </OpacityAnimation>
        </div>
        <VerticalMoveAnimation
          element="div"
          delay={0.2}
          duration={0.5}
          direction="top"
        >
          <OpacityAnimation element="strong" delay={0.2} duration={1}>
            ALL IN ONE CARSAYO
          </OpacityAnimation>
          <OpacityAnimation element="p" delay={0.25} duration={1.5}>
            <span>카사요는 자동차 관련 모든 서비스를 전문적으로 제공하는</span>
            <span>자동차 쇼핑 통합 플랫폼으로</span>
            <span>참신하고 혁신적인 비즈니스 모델로</span>
            <span>자동차 산업에 기여하고 있습니다.</span>
          </OpacityAnimation>
          <OpacityAnimation element="p" delay={0.3} duration={1.5}>
            <span>정보의 비대칭을 해결하고 긍정적인 인식 변화를 이끌어</span>
            <span>자동차 시장을 선도할 것입니다</span>
          </OpacityAnimation>
        </VerticalMoveAnimation>
        <ul>
          <VerticalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="bottom"
          >
            <span>회사명</span>
            <strong>카사요(주)</strong>
          </VerticalMoveAnimation>
          <VerticalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="bottom"
          >
            <span>대표이사</span>
            <strong>최용민</strong>
          </VerticalMoveAnimation>
          <VerticalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="bottom"
          >
            <span>설립일</span>
            <strong>2020년 8월 7일</strong>
          </VerticalMoveAnimation>
        </ul>
      </section>
    );
  };
  // section03 - 사업영역
  const Section03 = () => {
    const [businessType, setBusinessType] = useState("all");
    const handleClickEvents = {
      selectedBusiness: (type: string) => () => {
        setBusinessType(type);
      },
    };
    return (
      <section className="m-overview03" id="overview02">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          사업영역
        </VerticalMoveAnimation>
        <div className="m-overview03__top">
          <ul>
            <li>
              <img src="../imgs/gif/carsayo_splash.gif" alt="카사요 스플래시" />
            </li>
            <li>
              <img
                src="../imgs/gif/carsayo_splash02.gif"
                alt="카사요 스플래시2"
              />
            </li>
          </ul>
        </div>
        <div className="m-overview03__bottom">
          <div>
            <button
              className={`${businessType === "all" && "active"}`}
              onClick={handleClickEvents.selectedBusiness("all")}
            >
              전체보기
            </button>
            <button
              className={`${businessType === "25" && "active"}`}
              onClick={handleClickEvents.selectedBusiness("25")}
            >
              26년 사업
            </button>
          </div>
          <ul>
            <li className={`${businessType !== "25" && "active"}`}>
              <div>
                <img src="../imgs/svg/carIcon.svg" alt="자동차 아이콘" />
              </div>
              <span>리스 / 장기렌트</span>
            </li>
            <li className={`${businessType !== "25" && "active"}`}>
              <div>
                <img src="../imgs/svg/carIcon.svg" alt="자동차 아이콘" />
              </div>
              <span>신차 구매</span>
            </li>
            <li className={`${businessType !== "25" && "active"}`}>
              <div>
                <img src="../imgs/svg/crownIcon.svg" alt="왕관 아이콘" />
              </div>
              <span>수퍼카 판매</span>
            </li>
            <li className={`${businessType !== "25" && "active"}`}>
              <div>
                <img
                  src="../imgs/svg/receipt-editIcon.svg"
                  alt="내차팔기 아이콘"
                />
              </div>
              <span>내 차 팔기</span>
            </li>
            <li className={`${businessType !== "25" && "active"}`}>
              <div>
                <img
                  src="../imgs/svg/receiptIcon.svg"
                  alt="자동차보험 아이콘"
                />
              </div>
              <span>자동차 다이렉트 보험</span>
            </li>
            <li className={`${businessType !== "25" && "active"}`}>
              <div>
                <img
                  src="../imgs/svg/3d-rotateIcon.svg"
                  alt="자동차 수입/수출 아이콘"
                />
              </div>
              <span>자동차 수입 / 수출</span>
            </li>
            <li className="active">
              <div>
                <img
                  src="../imgs/svg/presention-chartIcon.svg"
                  alt="ai 아이콘"
                />
              </div>
              <span>AI 가격 추적 시스템</span>
            </li>
            <li className="active">
              <div>
                <img
                  src="../imgs/svg/smart-carIcon.svg"
                  alt="정비 탁송 아이콘"
                />
              </div>
              <span>정비 및 탁송 서비스</span>
            </li>
            <li className="active">
              <div>
                <img src="../imgs/svg/drivingIcon.svg" alt="단기렌트 아이콘" />
              </div>
              <span>단기 렌트 서비스</span>
            </li>
          </ul>
        </div>
      </section>
    );
  };
  // section04 - 기업연혁
  const Section04 = () => {
    // 오디오 파일
    const audioFiles = [
      "../audio/carsayoA.wav",
      "../audio/carsayoB.wav",
      "../audio/carsayoC.wav",
      "../audio/carsayoD.wav",
    ];

    const audioRef = useRef<HTMLAudioElement>(null);
    const [audioPlay, setAudioPlay] = useState<boolean | null>(null);

    const playRandomAudio = () => {
      if (audioPlay) {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        setAudioPlay(null);
      } else {
        const randomIndex = Math.floor(Math.random() * audioFiles.length);
        if (audioRef.current) {
          audioRef.current.src = audioFiles[randomIndex];
          audioRef.current.play();
          setAudioPlay(true);
        }
      }
    };
    return (
      <section className="m-overview04" id="overview03">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          기업연혁
        </VerticalMoveAnimation>
        <div className="m-overview04__contents">
          <div className="history">
            <div className="history__title">
              <HorizontalMoveAnimation
                element="h3"
                delay={0.1}
                duration={0.5}
                direction="left"
              >
                2024년
              </HorizontalMoveAnimation>
              <img src="../imgs/png/overview-section04-phone.png" alt="목업" />
            </div>
            <ul className="history__box">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">6월</div>
                  <p>
                    <span>공식 카사요 앱 출시</span>
                  </p>
                </div>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">5월</div>
                  <p>
                    <span>벤처 기업 인증(혁신 성장형)</span>
                    <span className="small">중소벤처기업부 벤처인증 획득</span>
                  </p>
                </div>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">5월</div>
                  <p>
                    <span>기업부설 연구소 설립</span>
                    <span className="small">
                      과학기술정보통신부 인정서 획득
                    </span>
                  </p>
                </div>
                <ul className="img-box">
                  {/* <li
                    onClick={handleClickEvents.openImgPopup(
                      "",
                      "../imgs/svg/history-start-img.svg"
                    )}
                  >
                    <img
                      src="../imgs/svg/history-start-img.svg"
                      alt="RE100가입기업인증서"
                    />
                  </li> */}
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "벤처기업 확인서",
                      "../imgs/svg/ventureImg.svg"
                    )}
                  >
                    <img
                      src="../imgs/svg/ventureImg.svg"
                      alt="벤처기업 인증 이미지"
                    />
                  </li>
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "기업 부설 연구소 설립 인증서",
                      "../imgs/svg/laboratoryImg.svg"
                    )}
                  >
                    <img
                      src="../imgs/svg/laboratoryImg.svg"
                      alt="기업 부설 연구소 설립 이미지"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">1월</div>
                  <p>
                    <span>ISO 9001(품질경영시스템) 국제 표준 인정</span>
                    <span>ISO 14001(환경경영시스템) 국제 표준 인정</span>
                  </p>
                </div>
                <ul className="img-box">
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "인증서",
                      "../imgs/svg/certificateImg01.svg",
                      "ISO 9001(품질경영시스템) 국제 표준 인정"
                    )}
                  >
                    <img
                      src="../imgs/svg/certificateImg01.svg"
                      alt="품질경영시스템 인증 이미지"
                    />
                  </li>
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "인증서",
                      "../imgs/svg/certificateImg02.svg",
                      "ISO 14001(환경경영시스템) 국제 표준 인정"
                    )}
                  >
                    <img
                      src="../imgs/svg/certificateImg02.svg"
                      alt="환경경영시스템 인증 이미지"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </ul>
          </div>
          <div className="history">
            <div className="history__title">
              <HorizontalMoveAnimation
                element="h3"
                delay={0.1}
                duration={0.5}
                direction="left"
              >
                2023년
              </HorizontalMoveAnimation>
            </div>
            <ul className="history__box">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">12월</div>
                  <p>
                    <span>대출성 금융 상품판매대리 중개업 등록</span>
                  </p>
                </div>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">11월</div>
                  <p>
                    <span>연구 개발 전담 부서 설립</span>
                  </p>
                </div>
                <ul className="img-box">
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "인증서",
                      "../imgs/svg/history-2023-img01.svg"
                    )}
                  >
                    <img
                      src="../imgs/svg/history-2023-img01.svg"
                      alt="대출성 금융 상품판매대리 중개업 등록 이미지"
                    />
                  </li>
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "인증서",
                      "../imgs/svg/history-2023-img02.svg"
                    )}
                  >
                    <img
                      src="../imgs/svg/history-2023-img02.svg"
                      alt="연구 개발 전담 부서 설립 이미지"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </ul>
          </div>
          <div className="history">
            <div className="history__title">
              <HorizontalMoveAnimation
                element="h3"
                delay={0.1}
                duration={0.5}
                direction="left"
              >
                2022년
              </HorizontalMoveAnimation>
            </div>
            <ul className="history__box">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">7월~</div>
                  <p>
                    <span>라디오 광고 (가수 배기성 나레이션 및 노래)</span>
                  </p>
                </div>
                <div className="audio-box">
                  <h3>
                    <CarsayoCI fill="#111" />
                  </h3>
                  <p>브랜드 오디오 클립</p>
                  <button onClick={playRandomAudio}>
                    {audioPlay ? (
                      <div>
                        <span>
                          <img
                            src="../imgs/svg/audio-stop.svg"
                            alt="정지 아이콘"
                          />
                        </span>
                        <ul
                          className={`audio-visualizer ${
                            audioPlay ? "playing" : ""
                          }`}
                        >
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                    ) : (
                      <img
                        src="../imgs/svg/m-main-swiper-playBtn.svg"
                        alt="오디오 재생 아이콘"
                      />
                    )}

                    <audio ref={audioRef} loop />
                  </button>
                </div>
              </HorizontalMoveAnimation>
            </ul>
          </div>
          <div className="history">
            <div className="history__title">
              <HorizontalMoveAnimation
                element="h3"
                delay={0.1}
                duration={0.5}
                direction="left"
              >
                2021년
              </HorizontalMoveAnimation>
            </div>
            <ul className="history__box">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">11월</div>
                  <p>
                    <span>카사요 앱 Android 버전 출시</span>
                  </p>
                </div>
                <ul className="img-box">
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "카사요 구 버전 Android 심볼",
                      "../imgs/png/AOS_prevSimbol.png"
                    )}
                  >
                    <img
                      style={{ width: "140px" }}
                      src="../imgs/png/AOS_prevSimbol.png"
                      alt="구버전Android심볼"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </ul>
          </div>
          <div className="history">
            <div className="history__title">
              <HorizontalMoveAnimation
                element="h3"
                delay={0.1}
                duration={0.5}
                direction="left"
              >
                2020년
              </HorizontalMoveAnimation>
            </div>
            <ul className="history__box">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">12월</div>
                  <p>
                    <span>카사요 앱 iOS 버전 출시</span>
                  </p>
                </div>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <div className="content">
                  <div className="month">8월</div>
                  <p>
                    <span>카사요(주) 설립</span>
                  </p>
                </div>
                <ul className="img-box">
                  <li
                    onClick={handleClickEvents.openImgPopup(
                      "카사요 구 버전 iOS 심볼",
                      "../imgs/svg/symbol-previous.svg"
                    )}
                  >
                    <img
                      style={{ width: "140px" }}
                      src="../imgs/svg/symbol-previous.svg"
                      alt="구버전심볼"
                    />
                  </li>
                  <li
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #e5e5ec",
                    }}
                    onClick={handleClickEvents.openImgPopup(
                      "카사요 베타 버전 로고",
                      "../imgs/svg/logo-previous.svg"
                    )}
                  >
                    <img
                      src="../imgs/svg/logo-previous.svg"
                      style={{ height: "100px" }}
                      alt="구버전로고"
                    />
                  </li>
                </ul>
              </HorizontalMoveAnimation>
            </ul>
          </div>
        </div>
      </section>
    );
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isPopupOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > window.innerHeight) {
        setBiggerScreen("width");
      } else {
        setBiggerScreen("height");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   scrollToHash();
  //   window.addEventListener("hashchange", scrollToHash);

  //   return () => {
  //     window.removeEventListener("hashchange", scrollToHash);
  //   };
  // }, []);

  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <Section03 />
      <Section04 />
      <CarsayoFullPopup
        title={imgPopup.title}
        isShown={isPopupOpen}
        onClose={() => setPopupOpen(!isPopupOpen)}
      >
        <div className="popupContent">
          {imgPopup.content && (
            <p>
              <img src="../imgs/svg/Star.svg" alt="인증아이콘" />
              {imgPopup.content}
            </p>
          )}
          <div
            style={{
              width: biggerScreen === "width" ? "" : "90vw - 20px",
              height: biggerScreen === "height" ? "" : "calc(90vh - 130px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <TransformWrapper
              minScale={0.5} // 최소 줌
              maxScale={5} // 최대 줌
              centerOnInit={true}
            >
              <TransformComponent>
                <img
                  src={imgPopup.url}
                  alt={imgPopup.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      </CarsayoFullPopup>
    </div>
  );
};
export default CarsayoMobileOverView;
